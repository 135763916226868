<template>
  <v-container
    id="login-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            color="accent"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  Login
                </div>
                <!-- <div class="mt-4">
                  <v-btn icon class="mx-2">
                    <v-icon> mdi-facebook </v-icon>
                  </v-btn>
                  <v-btn icon class="mx-2">
                    <v-icon> mdi-twitter </v-icon>
                  </v-btn>
                  <v-btn icon class="mx-2">
                    <v-icon> mdi-google </v-icon>
                  </v-btn>
                </div> -->
              </div>
            </template>

            <v-card-text class="text-center">
              <!-- <div class="text-center font-weight-light">Or Be Classical</div> -->

              <v-text-field
                v-model="username"
                class="mt-10"
                color="accent"
                placeholder="Username"
                prepend-icon="mdi-face"
                :rules="[rules.required]"
              />

              <!-- <v-text-field
                color="secondary"
                placeholder="Email..."
                prepend-icon="mdi-email"
              /> -->

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                class="mb-8"
                color="accent"
                placeholder="Password"
                prepend-icon="mdi-lock-outline"
                :rules="[rules.required]"
                @click:append="showPassword = !showPassword"
                @keyup.enter="login"
              />

              <v-btn
                color="accent"
                rounded
                text
                large
                @click="login"
              >
                Let's Go
              </v-btn>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LoginView',

    data: () => ({
      username: '',
      password: '',
      showPassword: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook',
        },
        {
          href: '#',
          icon: 'mdi-twitter',
        },
        {
          href: '#',
          icon: 'mdi-github',
        },
      ],
    }),
    created () {
    // this.init()
    },
    methods: {
      login: function () {
        if (
          this.username &&
          this.username.trim().length !== 0 &&
          this.password &&
          this.password.trim().length !== 0
        ) {
          var username = this.username
          var password = this.password
          this.$store
            .dispatch('authUser/login', { username, password })
            .then(() =>
              this.$toast.success(
                'Logged in successfully.',
                'Success',
                {
                  position: 'topRight',
                },
                this.$router.push('/'),
              ),
            )
            .catch(() =>
              this.$toast.error('Incorrect username or password.', 'Error', {
                position: 'topRight',
              }),
            )
        // .then(() => );
        }
      },
      init: function () {
        if (this.$store.getters['authUser/isLoggedIn']) {
          this.$router.push('/')
        }
      },
    },
  }
</script>
